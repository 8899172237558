<header class="header" fxflex="100" fxLayout="row" fxLayout.lt-sm="row">
  <div class="logo" fxFlex.lt-lg="1 1 54%" fxFlex.xl="1 1 20%" fxFlex.lt-md="1 1 10%" fxFlex.lt-sm="1 1 10%" fxFlex.lg="1 1 68%">
    <ng-container *ngIf="!isLandingPage">
      <a href="/" name="PolarVisor homepage">
        <img src="../assets/images/polarvisor-logo.svg" alt="PolarVisor Homepage" title="PolarVisor Homepage" />
      </a>
      <button class="mobile-menu-button" (click)="setSidebarOpen()">
        <span class="material-icons"> menu </span>
      </button>
    </ng-container>
    <ng-container *ngIf="isLandingPage">
      <a href="{{homeUrl}}" name="PolarVisor homepage">
        <img src="../assets/images/polarvisor-logo.svg" alt="PolarVisor Homepage" title="PolarVisor Homepage" />
      </a>
      <a style="position: relative;height: 100%;" class="landing-page-home" href="{{homeUrl}}" name="PolarVisor homepage">
        <img style="" src="../assets/images/frigoriste.png" alt="PolarVisor Homepage" title="PolarVisor Homepage" />
      </a>
    </ng-container>
  </div>
  <!-- /.logo -->
  <nav
    fxFlex.lt-lg="1 1 46%"
    fxFlex.xl="1 1 80%"
    fxFlex.lt-sm="1 1 90%"
    fxFlex.lt-md="1 1 90%"
    fxFlex.lg="1 1 32%"
    fxLayout="row"
    fxLayoutAlign="flex-end"
  >
    <ul class="nav-controllers">
      <li>
        <app-language-switcher></app-language-switcher>
      </li>
      <ng-container *ngIf="isLandingPage">
        <li class="landing-page-element"><a href="https://polarvisor.com" target="_blank">{{'LANDING_PAGE.LANDING_PAGE_TEXT_34_V2' | translate }}</a></li>
      </ng-container>
      <li *ngIf="isAuthenticated">
        <a (click)="logout()"><span class="custom-icon custom-icon-logout"></span></a>
      </li>
    </ul>
    <div class="profile-wrapper" *ngIf="isAuthenticated">
      <app-profile-image class="openDialog" [userInfo]="userInfo"></app-profile-image>
      <span class="user-name">{{ userInfo?.name }}</span>
    </div>
    <div class="profile-wrapper" *ngIf="isAdmin">
      <span class="user-name login" (click)="goToAdmin()">Admin</span>
    </div>
    <!-- /.profile-wrapper -->
  </nav>
</header>
