import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationsResolver } from './shared/resolvers/translations.resolver';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: localStorage.getItem('defaultLanguage') + '/dashboard' },
  {
    path: ':lang',
    // canActivateChild: [AuthGuard],
    resolve: {
      lang: TranslationsResolver
    },
    children: [
      { path: '', pathMatch: 'full', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'equipment',
        loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'migration',
        loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
      },
      {
        path: 'migration-end',
        loadChildren: () => import('./landing-page-v2/landing-page-v2.module').then(m => m.LandingPageV2Module),
      },
      {
        path: 'migration-form',
        loadChildren: () => import('./user-migration/user-migration-form.module').then(m => m.UserMigrationFormModule),
      },
      {
        path: 'migration-validation',
        loadChildren: () => import('./user-migration-validation/user-migration-validation.module')
          .then(m => m.UserMigrationValidationModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'customer-locations',
        loadChildren: () => import('./customer-locations/customer-location.module').then(m => m.CustomerLocationModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
