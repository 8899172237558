import { Injectable } from '@angular/core';
import { GraphqlService } from '@graphql/graphql.service';
import gql from 'graphql-tag';
import { map, take } from 'rxjs/operators';
import {Countries} from '@shared/models/countries';
import {InstallerSearchCriteria} from '@shared/services/installer-search-criteria.interface';
import {InstallerChainSearchCriteria} from '@shared/services/installer-chain-search-criteria.interface';

const DEFAULT_COUNTRIES_SEARCH_CRITERIA: InstallerSearchCriteria = {
  filters: {},
  sort: {
    field: 'name',
    direction: 'ASC'
  },
  pagination: {
    page: 1,
    perPage: 1000
  }
};

const DEFAULT_COUNTRIES_CHAINS_SEARCH_CRITERIA: InstallerChainSearchCriteria = {
  filters: {},
  sort: {
    field: 'name',
    direction: 'ASC'
  },
  pagination: {
    page: 1,
    perPage: 1000
  }
};
@Injectable({
  providedIn: 'root'
})
export class CountriesService {
  constructor(private graphqlService: GraphqlService) {}
  private getAllCountriesQuery: any = gql`
      query getAllCountries {
        getAllCountries {
        code
        defaultLabel
        countryISO
        }
      }
    `;

  public getAllCountries(): Promise<Countries[]> {
    return this.graphqlService
      .query(this.getAllCountriesQuery, {}, false)
      .pipe(
        take(1),
        map(result => {
          return result.data.getAllCountries;
        })
      )
      .toPromise() as Promise<Countries[]>;
  }
}
