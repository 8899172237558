import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserService } from '@users/domain/services/user.service';

@Component({
  selector: 'app-licence-dialog',
  templateUrl: './licence-dialog.component.html',
  styleUrls: ['./licence-dialog.component.scss']
})
export class LicenceDialogComponent {

  license : string = '-----';
  root : string = '-----';
  dni = new FormControl('', [Validators.required]);

  constructor( private userService: UserService ) { }

  async submit(){

    // get binary value of dni number 
    const result = parseInt(this.dni.value).toString(2);
    // get complement of 2 value of dni number 
    const result_reversed = (~result & (Math.pow(2, result.length) - 1)).toString();

    if(this.dni.valid) {
      this.userService.getDniLicense(this.dni.value).subscribe(
        res => {
          this.license = res;
        },
        err => console.log('LicenceDialogComponent : Error in fetching licence', err)
      );
      this.userService.getDniLicense(result_reversed).subscribe(
        res => {
          this.root = res;
        },
        err => console.log('LicenceDialogComponent : Error in fetching Root Token', err)
      );
    }
  }

}
