<form [formGroup]='formGroup'>
  <div class='phone-container row'>
    <div class='col-md-3 indicatif mat-form-field-outline'>
      <ng-select
        class="mat-form-field" appearance="outline"
        [items]='countriesCodes'
        [clearable]='false'
        bindLabel='countryISO'
        (change)="handleIndicatifChange()"
        [(ngModel)]='selectedCountryCode'
        [notFoundText]="'N/A'"
        [placeholder]="'website.users.dialog.tabs.contact.fields.indicatif' | translate"
        aria-label="Type de fluide"
        formControlName="indicatif"
      ></ng-select>
    </div>
    <mat-form-field class='col-md-9' appearance="outline">
      <mat-label>{{ 'website.users.dialog.tabs.contact.fields.phone' | translate }}</mat-label>
      <input
        matInput
        name="phone"
        formControlName="phone"
        placeholder="{{ 'website.users.dialog.tabs.contact.fields.phone' | translate }}"
        (change)="handlePhoneChange($event)"
        [formControl]="phoneControl"
      />
    </mat-form-field>
  </div>
</form>

