import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/app.injector';

if (environment.production) {
  enableProdMode();
}


Sentry.init({
  dsn: "https://1f3c273f2116451fa6be9d2299abde70@o1040121.ingest.sentry.io/6480660",
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", "https://yourserver.io/api"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));

/* eslint-disable */
// @ts-ignore
// import { worker } from './mocks/browser';
// worker.start();
// worker.on('request:match', (req: any) => {
//   console.log('[MSW] request:match', req);
// });
// worker.on('response:mocked', (req: any, reqId: any) => {
//   console.log('[MSW] response:mocked; req:', req, 'reqId: ', reqId);
// });

/* eslint-enable */

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(err => console.error('Error in main.ts: ', err));
