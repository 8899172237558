import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@users/domain/services/user.service';

@Component({
  selector: 'app-authTokenDialog',
  templateUrl: './authTokenDialog.component.html',
  styleUrls: ['./authTokenDialog.component.scss']
})
export class AuthTokenDialogComponent implements OnInit {

  token : string = null;
  isTokenPresent : boolean = false;

  constructor( private userService: UserService ) { }

  public ngOnInit(){
     this.userService.getMyToken().subscribe(
      res => {
        this.token = res;
        this.isTokenPresent = true;
      },
      err => console.log('AuthTokenDialogComponent : Error in fetching token', err)
    );
  }
}
