import gql from 'graphql-tag';

export const fields = gql`
  fragment fields on User {
    _id
    email
    name
    firstName
    lastName
    password
    activationCode
    active
    blocked
    role {
      _id
      name
      class
    }
    profileImage
  }
`;

export const register = gql`
  mutation register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $recaptcha: String!
    $link: String!
    $locale: String!
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      recaptcha: $recaptcha
      link: $link
      locale: $locale
    ) {
      ...fields
    }
  }
  ${fields}
`;

export const login = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const changeLang = gql`
  mutation changeLang($lang: String!, $userId: String!) {
    changeLang(lang: $lang, userId: $userId)
  }
`;

export const activate = gql`
  mutation activate($email: String!, $code: String!) {
    activate(email: $email, code: $code)
  }
`;

export const askRecover = gql`
  mutation askRecover($email: String!, $link: String!, $locale: String!) {
    askRecover(email: $email, link: $link, locale: $locale)
  }
`;

export const recover = gql`
  mutation recover($email: String!, $code: String!, $password: String!) {
    recover(email: $email, code: $code, password: $password)
  }
`;

export const checkOldPassword = gql`
  mutation checkOldPassword($oldPassword: String!) {
    checkOldPassword(oldPassword: $oldPassword)
  }
`;

export const updateToken = gql`
  mutation updateToken($input: UserUpdateInput!) {
    updateToken(input: $input)
  }
`;

export const updateMyToken = gql`
  mutation updateMyToken($input: MyUserUpdateInput!) {
    updateMyToken(input: $input)
  }
`;

export const selfDelete = gql`
  mutation selfDelete($password: String!) {
    selfDelete(password: $password)
  }
`;

export const resendCode = gql`
  mutation resendCode($email: String!, $returnLink: String!) {
    resendCode(email: $email, returnLink: $returnLink)
  }
`;
