<h1 mat-dialog-title>Licence PolarBox</h1>
<div mat-dialog-content class="container">
  <div class="row input-btn">
    <div class="col">
      <mat-form-field appearance="fill">
        <mat-label>Identifiant de votre Polarbox</mat-label>
        <input matInput placeholder="2140" [formControl]="dni" required>
        <mat-error *ngIf="dni.invalid">numéro de DNI obligatoire</mat-error>
      </mat-form-field>
    </div>
    <div class="col">
      <button class="send-btn" (click)="submit()" mat-raised-button color="primary">Envoyer</button>
    </div>
  </div>
  <h4 class="pt-5">Votre Numéro de Licence: <b>{{license | uppercase}}</b></h4>
  <h4 class="pt-5">Votre Token Root: <b>{{root | uppercase}}</b></h4>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{'website.header.modal.footer' | translate}}</button>
</div>