import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserNotification} from "@users/domain/models/user-notification";
import {UserNotificationRulesInteraction} from "@users/domain/models/user-notification-rules-interaction";
import {
  NotificationRulesDialogService
} from "@shared/components/banner/notification-rules-dialog/notification-rules-dialog.service";
import { LanguageService } from '@shared/services/language.service';
import * as Showdown from 'showdown';


@Component({
  selector: 'notification-rules-dialog',
  templateUrl: './notification-rules-dialog.component.html',
  styleUrls: ['./notification-rules-dialog.component.scss']
})
export class NotificationRulesDialogComponent implements OnInit {

  public buttons: string[];
  public mode: number;
  public currentLanguage: string;
  public userNotifications: UserNotification[]
  public showdownOptions: Showdown.ShowdownOptions = {
    noHeaderId: true,
    emoji: true,
  };
  constructor(
    public dialogRef: MatDialogRef<NotificationRulesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public notificationRulesDialogService: NotificationRulesDialogService,
    public languageService: LanguageService
  ) {}

  public ngOnInit(): void {
    this.userNotifications = this.data.userNotifications;

    this.currentLanguage = this.languageService.currentLanguage.toUpperCase();
    if (this.data.userNotifications) {
      this.mode = 1
    } else {
      this.mode = 0;
    }
  }

  public async handleMarkAllNotificationRulesAsSeen(): Promise<void> {
    const savedPromises: Promise<boolean>[] = new Array<Promise<boolean>>();
    this.userNotifications.forEach(n => {
      const userNotificationRulesInteraction = new UserNotificationRulesInteraction();
      userNotificationRulesInteraction.idNotification = n.ruleId;
      userNotificationRulesInteraction.idUser = this.data.idUser;
      userNotificationRulesInteraction.seen = true;
      userNotificationRulesInteraction.action = 'accept';
      userNotificationRulesInteraction.idEquipment = n.idEquipment;
      userNotificationRulesInteraction.idSubscription = n.idSubscription;
      savedPromises.push(this.notificationRulesDialogService.markNotificationRuleAsSeen(userNotificationRulesInteraction));
    });
    const resolvedPromises = await Promise.all(savedPromises);
    console.log('resolvedPromises => ', resolvedPromises);
  }

  public async handleMarkNotificationRuleAsSeen(userNotification: UserNotification, result: string): Promise<void>{
    const userNotificationRulesInteraction = new UserNotificationRulesInteraction();
    userNotificationRulesInteraction.idNotification = userNotification.ruleId;
    userNotificationRulesInteraction.idUser = this.data.idUser;
    userNotificationRulesInteraction.seen = true; // QUESTION: à quoi
    userNotificationRulesInteraction.action = result;
    userNotificationRulesInteraction.idEquipment = userNotification.idEquipment;
    userNotificationRulesInteraction.idSubscription = userNotification.idSubscription;
    await this.notificationRulesDialogService.markNotificationRuleAsSeen(userNotificationRulesInteraction)
    if (this.mode === 0) {
      this.dialogRef.close();
    } else {
      this.data.userNotifications.splice(this.data.userNotifications.findIndex((nR: UserNotification) => nR.ruleId === userNotification.ruleId), 1)
      if (this.data.userNotifications.length === 0) {
        this.dialogRef.close();
      }

    }
  }
  public async handleCloseNotificationRule(userNotification: UserNotification): Promise<void> {
    await this.handleMarkNotificationRuleAsSeen(userNotification, 'close');
  }

  public async handleAcceptNotificationRule(userNotification: UserNotification): Promise<void> {
    await this.handleMarkNotificationRuleAsSeen(userNotification, 'accept');
  }

  public async handleRejectNotificationRule(userNotification: UserNotification): Promise<void> {
    await this.handleMarkNotificationRuleAsSeen(userNotification, 'reject');
  }

}
