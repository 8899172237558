import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@users/domain/services/user.service';

import { LanguageService } from '../services/language.service';
import { TokenSessionService } from '../services/token.session.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(  private tokenService: TokenSessionService,
                private router: Router,
                private languageService: LanguageService,
                private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkCanActivate(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkCanActivate(childRoute, state);
  }

  private checkCanActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('AuthGuard checkCanActivate this.tokenService.isAuthenticated()', this.tokenService.isAuthenticated());
    let url: string = state.url;
    this.userService.redirectUrl = url;
    if (window.location.href.includes('users/login')) {
      return true;
    }

    if (!this.tokenService.isAuthenticated()) {
      if (state.url.includes('migration-form') || state.url.includes('migration') || state.url.includes('migration-validation')) {
        return true;
      }
      const goToUrl: string = '/' + this.languageService.currentLanguage + '/users/login';
      console.log('AuthGuard checkCanActivate goToUrl', goToUrl);
      this.router.navigateByUrl(goToUrl);
      return false;
    } else {
      if (state.url.includes('migration-form') || state.url.includes('migration') || state.url.includes('migration-validation')) {
        window.location.href = 'https://sentinelle.matelex.fr/index.php?fromPolarvisor';
      }
    }
    return true;
  }
}
