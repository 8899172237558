// Apollo
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
// import { HttpLink } from 'apollo-angular-link-http';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloLink, from, InMemoryCache, NextLink, Operation } from '@apollo/client/core';

import { environment } from '../../environments/environment';
import { LanguageService } from '../shared/services/language.service';
import { TokenSessionService } from '../shared/services/token.session.service';
import { onError } from '@apollo/client/link/error';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeaturesEnum } from '@shared/models/features.enum';
import { first } from 'rxjs/operators';
import { FeaturesFlagService } from '@shared/services/features-flag.service';

@NgModule({
  exports: [HttpClientModule]
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    private httpLink: HttpLink,
    languageService: LanguageService,
    private tokenSessionService: TokenSessionService,
    private snackbar: MatSnackBar,
    private featuresFlagsService: FeaturesFlagService
  ) {
    // const link = ApolloLink.split(
    //   operation => {
    //     console.log('GraphQLModule constructor link operation', operation);
    //     // return operation.getContext().version === 1;
    //     return false;
    //   },
    //   httpLink.create({ uri: environment.mockApiUrl }),
    //   httpLink.create({ uri: environment.mockApiUrl })
    // );

    const authMiddleware = new ApolloLink((operation: Operation, forward: NextLink) => {
      const token = this.tokenSessionService.getToken();
      if (token) {
        operation.setContext({
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
        });
      }
      return forward(operation);
    });


    const errorLink = onError(({ graphQLErrors, networkError }) => {
      const networkErr = networkError as any;
      if (networkError) {
        if (networkErr.status === 500) {
          console.log(networkError);
          this.featuresFlagsService.getFeatureFlagByName(FeaturesEnum.ERROR_NOTIFICATION).pipe(first()).subscribe((feature => {
            if (feature?.active) {
              this.snackbar.open((networkError as any).error.errors[0].message2 ?? (networkError as any).error.errors[0].message, 'Ok', {
                duration: 2000
              });
            }
          }));
        }
      }
    });


    const serverLink = this.httpLink.create({
      uri:
        document.location.protocol +
        '//' +
        document.location.hostname +
        environment.apiUrl.replace('{{lang}}', languageService.currentLanguage)
    });

    apollo.create({
      link: from([errorLink, authMiddleware.concat(serverLink)]),
      // link,
      cache: new InMemoryCache()
    });

    apollo.createNamed('mockApiClient', {
      link: httpLink.create({
        uri: document.location.protocol + '//' + document.location.hostname + environment.mockApiUrl
      }),
      cache: new InMemoryCache()
    });
  }
}
